"use client";

import Link from "next/link";
import { redirect, useSearchParams } from "next/navigation";
import { signIn } from "next-auth/react";
import { forgotPasswordPaths } from "routes";
import useSWRMutation from "swr/mutation";
import { z } from "zod";

import Button from "@/components/atoms/Button";
import TextInput from "@/components/forms/controls/TextInput";
import Form from "@/components/forms/Form";
import { useZodForm } from "@/hooks/useZodForm";
import { useTranslation } from "@/i18n/client";
import { AllowedLanguages } from "@/types/generic";
import Icon from "../atoms/Icon";

interface LoginFormProps {
  className?: string;
  locale: AllowedLanguages;
}

export const LoginForm = ({ locale, className }: LoginFormProps) => {
  const { t } = useTranslation(locale);

  const schema = z.object({
    email: z.string().email(t("error.invalid_email")),
    password: z.string(),
  });

  const form = useZodForm({ schema });
  const searchParams = useSearchParams();

  async function login(
    url: string,
    {
      arg,
    }: {
      arg: z.infer<typeof schema>;
    },
  ) {
    return await signIn("credentials", {
      email: arg.email,
      password: arg.password,
      callbackUrl: `/${locale}/myvivaldis/dashboard`,
      redirect: false,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data, trigger, isMutating } = useSWRMutation(
    "/api/auth/callback/credentials",
    login,
  );

  const handleLinkedInLogin = () => {
    signIn("linkedin");
  };

  const handleFacebookLogin = () => {
    signIn("facebook");
  };

  const handleWorkIDLogin = () => {
    signIn("workid");
  };

  const handleSubmit = async (data: z.infer<typeof schema>) => {
    await trigger(data);
  };

  if (data?.ok) {
    if (data.url) {
      window.location.href = data.url;
    } else {
      redirect(`/${locale}/myvivaldis/profile`);
    }
  }

  return (
    <div className={className}>
      <h2 className="h3 !mb-6">{t("login.form_title")}</h2>
      {searchParams.get("password_reset") && (
        <div className="mb-4 rounded bg-positive-100 px-4 py-3">
          <h6 className="!mb-0">{t("login.password_reset_success.title")}</h6>
          <div>{t("login.password_reset_success.message")}</div>
        </div>
      )}
      {searchParams.get("password_set") && (
        <div className="mb-4 rounded bg-positive-100 px-4 py-3">
          <h6 className="!mb-0">{t("login.password_set_success.title")}</h6>
          <div>{t("login.password_set_success.message")}</div>
        </div>
        )}
      {searchParams.get("user_activated") && (
        <div className="mb-4 rounded bg-positive-100 px-4 py-3">
          <h6 className="!mb-0">{t("login.user_activated_success.title")}</h6>
          <div>{t("login.user_activated_success.message")}</div>
        </div>
      )}

      {/* Temporary message */}
      {! searchParams.get("password_reset") && ! searchParams.get("user_activated") && (
        <div className="mb-6 rounded border border-yellow px-4 py-3 flex">
          <Icon className="w-4 h-4 shrink-0 mr-3 mt-0.5 text-yellow-600" name="info" />
          <div
            className="prose max-w-full text-xs sm:text-sm text-black"
            dangerouslySetInnerHTML={{
              __html: t("login.new_website_reset_password.message"),
            }}
          ></div>
        </div>
      )}

      <Form
        form={form}
        className="flex flex-col gap-6"
        onSubmit={handleSubmit}
        noValidate={true}
      >
        <TextInput
          label={t("login.email.label")}
          placeholder={t("login.email.placeholder")}
          name="email"
          type="email"
          required
        />

        <TextInput
          label={t("login.password.label")}
          placeholder={t("login.password.placeholder")}
          name="password"
          type="password"
          required
        />

        {data?.ok === false && (
          <div className="text-negative">
            {data.error === "not_activated"
              ? t("login.error.not_activated")
              : t("login.error.invalid_credentials")}
          </div>
        )}

        <div className="flex items-center gap-y-6 gap-x-10 flex-wrap mt-4 lg:mt-6">
          <Button
            type="submit"
            disabled={isMutating}
            hasLoader={true}
            isLoading={isMutating}
            className="btn-lg"
          >
            {t("login.submit")}
          </Button>

          <Link
            href={`/${locale}/myvivaldis/${forgotPasswordPaths[locale]}`}
            className="link"
          >
            {t("login.forgot_password")}
          </Link>
        </div>
        <div className="flex items-center mt-4">
          <div className="border-t border-1 border-dashed flex-grow"></div>
          <div className="px-3 lg:px-4 text-black-300 uppercase">{t("login.or")}</div>
          <div className="border-t border-1 border-dashed flex-grow"></div>
        </div>
        <div className="flex items-center gap-y-4 gap-x-6 flex-wrap mt-4">
          {/* LinkedIn Login Button */}
          <Button
              type="button"
              icon={"linkedin"}
              iconClassName={"text-[#0077B5] -translate-y-[.14rem]"}
              arrow={false}
              onClick={handleLinkedInLogin}
              className="btn btn-social-login btn-social-login--linkedin btn-linkedin bg-white border-border 0 hover:bg-grey-100 text-black gap-2 font-semibold w-full "
          >
            {t("login.linkedin")}
          </Button>

          {/* Facebook Login Button */}
          <Button
              type="button"
              icon={"facebook"}
              iconClassName={"text-[#316FF6] -translate-y-[.1rem]"}
              arrow={false}
              onClick={handleFacebookLogin}
              className="btn btn-facebook btn-social-login--facebook btn-linkedin bg-white border-border 0 hover:bg-grey-100 text-black gap-2 font-semibold w-full "
          >
            {t("login.facebook")}
          </Button>

          {/* WorkID Login Button */}
          <Button
              type="button"
              icon={"workid"}
              iconClassName={"text-[#316FF6] -translate-y-[.1rem]"}
              arrow={false}
              onClick={handleWorkIDLogin}
              className="btn btn-workid btn-social-login--workid bg-white border-border 0 hover:bg-grey-100 text-black gap-2 font-semibold w-full "
          >
            {t("login.work_id")}
          </Button>
        </div>
      </Form>
    </div>
  );
};
